<template>
  <div class="container">
    <!-- 广告照片 -->
    <div class="ad-section">
      <img :src="adPhotoUrl" alt="广告照片">
    </div>

    <!-- 左侧商品类别列表和右侧商品明细 -->
    <div class="content-section">
      <!-- 左侧类别 -->
      <div class="category-list">
        <ul>
          <li v-for="category in categories"
              :key="category.category_id"
              :class="{ 'selected': selectedCategory === category.category_id }"
              @click="fetchProducts(category.category_id)">
            {{ category.name }}
          </li>
        </ul>
      </div>

      <!-- 右侧商品详情 -->
      <div class="product-details">
        <h2>商品详情</h2>
        <div class="product-grid">
          <div v-for="product in products" :key="product.map_id" class="product-item">
            <img :src="product.main_image_url" alt="商品图标" class="product-image"/>
            <div class="product-info">
              <p class="product-name">{{ product.goods_title }}</p>
              <p class="product-price">{{ product.show_sales_price }}</p>
            </div>
          </div>
        </div>
        <p v-if="!products.length" class="no-products">请选择一个类别查看商品详情。</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      adPhotoUrl: 'https://v8th.oss-cn-beijing.aliyuncs.com/tihuo_yun/spic/bailizhen.thvip.cn/uploadfile/202312/202312101141459684.png',
      selectedCategory: null,
      categories: [
        { category_id: 538, name: '野生岛淡干海参' },
        { category_id: 539, name: '野生岛即食海参' },
        { category_id: 490, name: '京选一卡通' },
        { category_id: 491, name: '美荻斯全球系列' },
        { category_id: 492, name: '美荻斯国潮系列' },
        { category_id: 493, name: '三只松鼠' },
        { category_id: 494, name: '沃隆' },
        { category_id: 495, name: '洽洽坚果' },
      ],
      products: []
    };
  },
  methods: {
    async fetchProducts(categoryId) {
      document.title = '2024中秋礼品';
      this.selectedCategory = categoryId;
      try {

        var list_product = [
          {goods_title:"淡干海参50g",show_sales_price:"880.00" ,main_image_url:"https://v8th.oss-cn-beijing.aliyuncs.com/tihuo_yun/spic/bailizhen.thvip.cn/uploadfile/202312/202312101200231087.png"},
          {goods_title:"淡干海参100g",show_sales_price:"1680.0" ,main_image_url:"https://v8th.oss-cn-beijing.aliyuncs.com/tihuo_yun/spic/bailizhen.thvip.cn/uploadfile/202312/202312101200094250.png"},
          {goods_title:"淡干海参100g",show_sales_price:"1880.00" ,main_image_url:"https://v8th.oss-cn-beijing.aliyuncs.com/tihuo_yun/spic/bailizhen.thvip.cn/uploadfile/202312/202312101200094250.png"}
      ];
    
        this.products = list_product;
      } catch (error) {
        console.error("获取商品失败: ", error);
        this.products = [];
      }
    }
  },
  mounted() {
    this.fetchProducts(this.categories[0].category_id);
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 205, 106, 0.20);
  height: 100vh;
  overflow: hidden;
}

.ad-section {
  width: 100%;
  height: 200px; /* Adjust height as needed */
  overflow: hidden;
}

.ad-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-section {
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100%;
  height: calc(100vh - 200px); /* Adjust height to fit below the ad */
}

.category-list {
  overflow-y: auto;
  padding: 10px;
  border-right: 1px solid #ddd;
}

.category-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.category-list li {
  padding: 10px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 10px;
  font-size: 0.9em; /* Smaller font size */
  color: #333; /* Black color for text */
  transition: background-color 0.3s, color 0.3s;
}

.category-list li.selected {
  background-color: #e60012;
  color: #fff;
}

.product-details {
  overflow-y: auto;
  padding: 10px;
}

.product-details h2 {
  margin-top: 0;
  font-size: 1.5em;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

.product-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
}

.product-image {
  width: 100%;
  height: 150px;
  object-fit: cover;
}

.product-info {
  padding: 10px;
}

.product-name {
  font-size: 0.9em;
  margin: 0;
}

.product-price {
  font-size: 0.9em;
  color: #888;
}

.no-products {
  text-align: center;
  font-size: 1.2em;
  color: #555;
}
</style>
